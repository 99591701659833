<template>
  <v-app>
    <div class="text-center" v-if="loading === true">
      <v-progress-circular
        :size="70"
        :width="10"
        color="blue"
        indeterminate
      >
      </v-progress-circular>
      <br/>
      お客様の情報を確認中です。<br/>
      処理が完了するまでお待ちください。
    </div>
    <div class="ma-5" v-if="loading === false">
        <b>よくある質問</b><br/>
        <p v-html="this.qa"></p>
      </div>
  </v-app>
</template>

<script>

import firebase from "firebase";
export default {
  name: 'rule',
  data(){
    return {
      loading: true,
      qa:"",
    }
  },

  created: function () {
      this.getWelcome()
  },
  methods: {
    async getWelcome() {
      var welcome = await firebase
        .functions()
        .httpsCallable("welcome");
      welcome().then((result) => {
        this.qa = result.data.welcome.qa
        console.log(this.qa)
        this.loading = false
      });
    },

  }
};
</script>
